.copyBtn {
  &:hover {
    transform: scale(1.3);
    translate: 1s;
  }
}

.w-98 {
  width: 98% !important;
}

.reward-amount {
  border: 1px solid;
  border-radius: 5px;
}

.reward-amount input:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}

.e-admin-border {
  border: 1px solid #EBEDF3 !important;
  border-radius: 10px !important;
}