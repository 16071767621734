#root {
    height: 100%;
}
::-webkit-scrollbar {
    scrollbar-color: #3f51b5 #F5F5F5;
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
    border: 1px solid #3f51b5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #3f51b5;
}